import { StaticImage } from 'gatsby-plugin-image';
import { MultiCodeBlock, VideoFrame } from '@imgly-labs/gatsby-theme-imgly-docs';
import TrialLicenseHint from 'shared/trial_license_hint.mdx';
import * as React from 'react';
export default {
  StaticImage,
  MultiCodeBlock,
  VideoFrame,
  TrialLicenseHint,
  React
};