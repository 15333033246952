import { ReactComponent as Angular } from "../../../../../../assets/images/imgly/angular.svg";
import { ReactComponent as Cordova } from "../../../../../../assets/images/imgly/cordova.svg";
import { ReactComponent as Flutter } from "../../../../../../assets/images/imgly/flutter.svg";
import { ReactComponent as Ionic } from "../../../../../../assets/images/imgly/ionic.svg";
import { ReactComponent as ReactIcon } from "../../../../../../assets/images/imgly/react.svg";
import { ReactComponent as ReactNative } from "../../../../../../assets/images/imgly/reactnative.svg";
import { ReactComponent as Ruby } from "../../../../../../assets/images/imgly/rubygem.svg";
import { ReactComponent as RubyOnRails } from "../../../../../../assets/images/imgly/rubyrails.svg";
import { ReactComponent as Vue } from "../../../../../../assets/images/imgly/vue.svg";
import * as React from 'react';
export default {
  Angular,
  Cordova,
  Flutter,
  Ionic,
  ReactIcon,
  ReactNative,
  Ruby,
  RubyOnRails,
  Vue,
  React
};