import React from 'react';

import * as classes from './style.module.scss';

interface Props {
  type: string;
}

export const Type: React.FC<Props> = ({ type, children }) => {
  return (
    <span className={classes.root} data-after={type}>
      {children}
    </span>
  );
};
