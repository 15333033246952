import { ReactComponent as Android } from "../../../../../../assets/images/imgly/android.svg";
import { ReactComponent as Apple } from "../../../../../../assets/images/imgly/apple.svg";
import { ReactComponent as Server } from "../../../../../../assets/images/imgly/server.svg";
import { ReactComponent as Web } from "../../../../../../assets/images/imgly/web.svg";
import * as React from 'react';
export default {
  Android,
  Apple,
  Server,
  Web,
  React
};